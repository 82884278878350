<template>
  <form @submit.prevent="onsubmit">
    <va-input
      class="mb-3"
      v-model="email"
      type="email"
      :label="$t('auth.email')"
      :error="!!emailErrors.length"
      :error-messages="emailErrors"
    />

    <va-input
      class="mb-3"
      v-model="password"
      type="password"
      :label="$t('auth.password')"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />

    <div
      class="auth-layout__options d-flex align--center justify--space-between"
    >
      <va-checkbox
        v-model="keepLoggedIn"
        class="mb-0"
        :label="$t('auth.keep_logged_in')"
      />
    </div>

    <div v-if="authenticationStatus && authenticationStatus.state === 'failed'" class="my-3">
      <span style="color: red" class="d-flex justify--center">Not Authorized</span>
    </div>

    <div class="d-flex justify--center mt-3">
      <va-button @click="onsubmit" class="my-0">{{
        $t("auth.login")
      }}</va-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      keepLoggedIn: false,
      emailErrors: [],
      passwordErrors: [],
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "authenticationStatus"]),

    formReady() {
      return !this.emailErrors.length && !this.passwordErrors.length;
    }
  },
  mounted() {
    this.clearAuthenticationStatus();
  },
  methods: {
    ...mapActions(["clearAuthenticationStatus", "signIn"]),

    async onsubmit() {
      this.emailErrors = this.email ? [] : ["Email is required"];
      this.passwordErrors = this.password ? [] : ["Password is required"];
      if (!this.formReady) {
        return;
      }
      this.isLoading = true;
      try {
        await this.signIn({
        username: this.email,
        password: this.password,
        remember: this.keepLoggedIn
        });
        if (this.isAuthenticated) {
          this.$router.push({
            name: "dashboard"
          });
        }
      } catch (err) {
        console.log(err)
      }
      this.isLoading = false;
    }
  }
};
</script>
